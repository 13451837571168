<template>
  <vs-card>
    <div class="vx-row">
      <div class="vx-col md:w-1/2 w-full">
        <vs-input
          :success="!errors.first('basic.name') && formData.name != ''"
          val-icon-success="done"
          val-icon-danger="error"
          :danger="errors.first('basic.name') ? true : false"
          v-validate="'required'"
          name="name"
          data-vv-as="name"
          label="Name"
          class="w-full"
          data-vv-scope="basic"
          :danger-text="errors.first('basic.name')"
          v-model="formData.name"
          :disabled="isDefault"
        />
      </div>
      <div class="vx-col md:w-1/2 w-full">
        <vs-input
          :success="!errors.first('basic.unit') && formData.unit != ''"
          val-icon-success="done"
          val-icon-danger="error"
          :danger="errors.first('basic.unit') ? true : false"
          v-validate="'required'"
          name="unit"
          data-vv-as="unit"
          label="Unit"
          class="w-full"
          data-vv-scope="basic"
          :danger-text="errors.first('basic.unit')"
          v-model="formData.unit"
          :disabled="isDefault"
        />
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col md:w-1/2 w-full">
        <div class="mt-3 vx-col w-full">
          <label class="label" name="treatment-type">Body Parts</label>
          <div 
            ref="bodyPartContainer"
            class="bodyPartClass"
            :class="{'customBodyPartsErrBorder': !hasBodyParts}"
          >
            <div v-if="!formData.applicableAreas.length" class="items-center p-2">
              <p>Start adding body parts by pressing the 'Add Applicable Area' button.</p>
            </div>
            <div
              v-for="(item, index) in formData.applicableAreas"
              :key="item"
              :class="['inline-block mr-2', dynamicPadding]"
            >
              <div class="areaClass flex items-center gap-2 px-2 py-1 rounded">
                <p>{{ item }}</p>
                <vs-icon
                  class="cursor-pointer"
                  icon="icon-x"
                  icon-pack="feather"
                  @click="removeBodyParts(index)"
                ></vs-icon>
              </div>
            </div>
          </div>
          <span v-if="!hasBodyParts" class="pl-1 con-text-validation text-danger">Body Parts is required</span>
          <!-- <v-select
            multiple
            :closeOnSelect="false"
            label="name"
            v-validate="'required'"
            danger-text="This field is invalid"
            :options="optionBodyParts"
            v-model="formData.applicableAreas"
            data-vv-as="applicableAreas"
            data-vv-scope="basic"
            name="applicableAreas"
            class="w-full"
            :disabled="isDefault"
          >
          </v-select> -->
          <div
            class="con-text-validation span-text-validation-danger vs-input--text-validation-span v-enter-to"
            style="height: 19px"
          >
            <span class="span-text-validation">
              {{ errors.first("basic.applicableAreas") }}
            </span>
          </div>
        </div>
      </div>
      <div class="vx-col md:w-1/2 w-full">
        <div class="md:mt-3 mt-0 vx-col w-full">
          <label class="label" name="treatment-type">Treatment Type</label>
          <vs-select
            placeholder="Select treatment type"
            autocomplete
            v-model="selectedTreatmentType"
            v-validate="'required'"
            data-vv-as="treatmentType"
            data-vv-scope="basic"
            name="treatmentType"
            class="w-full"
            :disabled="isDefault"
          >
            <vs-select-item
              :key="index"
              :value="item._id"
              :text="item.name"
              v-for="(item, index) in treatmentTypesOption"
              :clearable="false"
            />
          </vs-select>
          <div
            class="con-text-validation span-text-validation-danger vs-input--text-validation-span v-enter-to"
            style="height: 19px"
          >
            <span class="span-text-validation">
              {{ errors.first("basic.treatmentType") }}
            </span>
          </div>
        </div>
      </div>
      <div v-if="isIvTheraphy" class="vx-col md:w-1/2 w-full">
        <div class="vx-col w-full">
          <label class="label" name="treatment-type">Ingredients</label>
          <vs-textarea
            :success="
              !errors.first('basic.ingredients') && formData.ingredients != ''
            "
            val-icon-success="done"
            val-icon-danger="error"
            :danger="errors.first('basic.ingredients') ? true : false"
            v-validate="'required'"
            data-vv-as="ingredients"
            data-vv-scope="basic"
            :danger-text="errors.first('basic.ingredients')"
            height="200px"
            name="ingredients"
            v-model="formData.ingredients"
            :disabled="isDefault"
          />
          <div
            class="con-text-validation span-text-validation-danger vs-input--text-validation-span v-enter-to"
            style="height: 19px"
          >
            <span class="span-text-validation">
              {{ errors.first("basic.ingredients") }}
            </span>
          </div>
        </div>
      </div>
      <div
        class="vx-col md:w-1/2 w-full"
        v-if="
          this.productDetail.treatmentType &&
          this.productDetail.treatmentType.treatmentTiming
        "
      >
        <div class="vx-col w-full">
          <label class="label">Timing of Treatment</label>
          <div
            class="p-2 py-3 border mb-2"
            style="border: solid 1px #aaa; border-radius: 5px"
          >
            {{ this.productDetail.treatmentType.treatmentTiming }}
          </div>
          <vs-checkbox v-model="formData.timingOverride" :disabled="isDefault"
            >Override Timing of Treatment</vs-checkbox
          >
          <vs-textarea
            :success="
              !errors.first('basic.treatmentTiming') &&
              formData.treatmentTiming != ''
            "
            val-icon-success="done"
            val-icon-danger="error"
            :danger="errors.first('basic.treatmentTiming') ? true : false"
            v-validate="formData.timingOverride && 'required'"
            name="treatmentTiming"
            data-vv-as="Treatment Timing"
            class="w-full"
            data-vv-scope="basic"
            :danger-text="errors.first('basic.treatmentTiming')"
            v-model="formData.treatmentTiming"
            style="margin: 5px 0 !important"
            :disabled="isDefault"
          />
          <div
            class="con-text-validation span-text-validation-danger vs-input--text-validation-span v-enter-to"
            style="height: 19px"
          >
            <span class="span-text-validation">
              {{ errors.first("basic.treatmentTiming") }}
            </span>
          </div>
        </div>
      </div>
      <div class="vx-col md:w-1/2 w-full">
        <div class="vx-col w-full">
          <label class="label" name="treatment-type">Aftercare Template</label>
          <vs-select
            placeholder="Select aftercare template"
            autocomplete
            v-model="formData.aftercaretemplate"
            name="aftercaretemplate"
            class="w-full"
            :disabled="isDefault"
          >
            <vs-select-item
              :key="index"
              :value="item._id"
              :text="item.name"
              v-for="(item, index) in aftercareTemplatesOption"
              :clearable="false"
            />
          </vs-select>
          <div
            class="con-text-validation span-text-validation-danger vs-input--text-validation-span v-enter-to"
            style="height: 19px"
          >
            <span class="span-text-validation">
              {{ errors.first("basic.aftercaretemplate") }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <vs-row>
      <vs-col vs-type="flex" vs-align="left" vs-w="12">
        <div class="w-full flex sm:justify-end sm:flex-row flex-col gap-4">
          <vs-button class="p-2" type="border" @click="() => $router.go(-1)">Cancel</vs-button>
          <vs-button @click="saveData" icon-pack="feather" :disabled="isDefault"
            ><span v-if="update">Update Product</span
            ><span v-else>Add Product</span></vs-button
          >
          <vs-button
            color="primary"
            @click="addOnlyBodyPartsPopupShow"
            icon-pack="feather"
            :disabled="isDefault"
            >Add Applicable Area</vs-button
          >
        </div>
      </vs-col>
    </vs-row>
    <vs-sidebar
      class="applicableAreaClass relative overflow-auto"
      parent="body"
      position-right
      click-not-close
      v-model="popupBodyPartsAdd"
    >
      <!-- HEADER -->
      <div class="headerClass space-y-3">
        <div class="flex items-center justify-between pt-4 px-4">
          <p class="text-xl font-semibold">Products Treatment Area</p>
          <vs-button
            v-if="!showAddApplicableAreaField"
            icon="add"
            type="border"
            class="p-2"
            @click="showAddApplicableAreaField = true"
            >Add</vs-button
          >
        </div>
        <p class="px-4">
          Manage your treatment areas.
        </p>
        <vs-divider></vs-divider>
        <vs-input
          v-model="searchApplicableArea"
          @input="customFilter"
          placeholder="Search applicable area"
          class="w-full px-4"
          :class="{ 'pb-4': !showAddApplicableAreaField }"
        />
        <vs-divider v-if="showAddApplicableAreaField"></vs-divider>
        <transition name="slide-fade">
          <div
            v-if="showAddApplicableAreaField"
            class="flex items-start justify-between gap-2 pb-4 px-4 overflow-hidden"
          >
            <vs-input
              val-icon-danger="error"
              :danger="
                errors.first('applicableArea.applicableAreaName') ||
                createBodyPartError
                  ? true
                  : false
              "
              v-validate="'required'"
              name="applicableAreaName"
              data-vv-as="applicableAreaName"
              placeholder="Applicable Area Name"
              class="w-full"
              data-vv-scope="applicableArea"
              :danger-text="
                errors.first('applicableArea.applicableAreaName') ||
                createBodyPartError
              "
              v-model="applicableArea.name"
            />
            <div class="flex items-center justify-between gap-2">
              <vs-button
                class="customButtonWidth"
                type="border"
                @click="addNewBodyParts"
                >Add</vs-button
              >
              <vs-button
                class="customButtonWidth"
                color="danger"
                type="border"
                @click="handleCancelCreateBodyPart"
                >Cancel</vs-button
              >
            </div>
          </div>
        </transition>
      </div>
      <!-- BODY -->
      <div v-if="bodyParts.length" class="scrollableContent pl-0">
        <div v-for="item in bodyParts" :key="item._id" class="px-5 relative">
          <div class="bg-primary2 text-white pl-2 select-none">
            {{ item.group }}
          </div>
          <div v-if="!item.data.length">
            <p>No Applicable Areas Found</p>
          </div>
          <div
            v-for="tr in item.data"
            :key="tr._id"
            :class="{ 'py-2': item.group === 'Fresh Clinics' }"
          >
            <div
              v-if="isCustomAreaEdit && selectedCustomArea._id === tr._id"
              class="flex justify-between gap-2 pt-2"
              :class="{
                'items-start':
                  updateApplicableAreaError ||
                  selectedCustomArea._id === tr._id,
              }"
            >
              <vs-input
                val-icon-danger="error"
                v-model="tr.name"
                class="w-full customInputClass"
                v-validate="'required'"
                data-vv-as="Applicable Area Name"
                name="customApplicableArea"
                data-vv-scope="applicableArea"
                :danger="
                  errors.first('applicableArea.customApplicableArea') ||
                  (updateApplicableAreaError &&
                    selectedCustomArea._id === tr._id)
                    ? true
                    : false
                "
                :danger-text="
                  errors.first('applicableArea.customApplicableArea') ||
                  updateApplicableAreaError
                "
              />
              <div class="flex items-center space-x-2">
                <vs-button
                  v-if="item.group === 'Custom'"
                  icon="icon-check"
                  icon-pack="feather"
                  type="border"
                  size="small"
                  @click="handleEditApplicableArea(tr)"
                ></vs-button>
                <vs-button
                  v-if="item.group === 'Custom'"
                  type="border"
                  size="small"
                  icon-pack="feather"
                  icon="icon-x"
                  color="danger"
                  @click="handleCancelEditApplicableArea"
                ></vs-button>
              </div>
            </div>
            <div
              v-else
              class="flex items-center justify-between space-y-2 relative"
            >
              <vs-checkbox
                class="custom-checkbox"
                v-model="formData.applicableAreas"
                :vs-value="tr.name"
              >
                {{ tr.name }}
              </vs-checkbox>
              <div class="flex items-center space-x-2">
                <div
                  v-if="deletePopup && tr._id === selectedCustomArea._id"
                  class="deleteAreaClass"
                >
                  <p>Are you sure you?</p>
                  <div class="flex items-center justify-end gap-2">
                    <vs-button
                      class="bg-white border-red-700 border border-solid p-2"
                      text-color="#c53030"
                      @click="handleDeleteApplicableArea"
                      :disabled="isDeletingApplicableArea"
                      >Yes</vs-button
                    >
                    <vs-button
                      class="bg-white border-primary border border-solid p-2"
                      text-color="#0e4333"
                      @click="handleCancelDelete"
                      >No</vs-button
                    >
                  </div>
                </div>
                <vs-button
                  v-if="item.group === 'Custom'"
                  icon="icon-edit"
                  icon-pack="feather"
                  type="border"
                  size="small"
                  @click="handleOpenApplicableAreaField(tr)"
                ></vs-button>
                <vs-button
                  v-if="item.group === 'Custom'"
                  type="border"
                  size="small"
                  icon-pack="feather"
                  icon="icon-trash"
                  color="danger"
                  @click="handleOpenDeletePopup(tr)"
                ></vs-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="w-full h-full flex items-center justify-center">
        <p class="italic opacity-75 md:text-lg text-base">
          No Applicable Areas Found
        </p>
      </div>
      <!-- FOOTER -->
      <div class="footerClass flex flex-col gap-3" slot="footer">
        <vs-button @click.prevent="handleSaveAreaSidebar">
          <!-- <vs-icon
            v-if="isSavingProducts"
            icon="refresh"
            class="animate-spin"
          ></vs-icon> -->
          Save
        </vs-button>
        <vs-button type="border" @click.prevent="handleCloseAreaSidebar">
          Exit
        </vs-button>
      </div>
    </vs-sidebar>
  </vs-card>
</template>

<script>
import { mapActions } from "vuex";
import vSelect from "vue-select";
import _ from "lodash";

export default {
  name: "CustomProductForm",
  components: {
    vSelect,
  },
  props: ["productDetail", "update", "isDefault"],
  data() {
    return {
      popupActivo2: false,
      popupBodyPartsAdd: false,
      newBodyParts: {
        name: "",
      },
      applicableArea: {
        name: "",
      },
      removeIndex: 0,
      popUpDelete: false,
      bodyParts: [],
      aftercareTemplatesOption: [],
      treatmentTypesOption: [],
      formData: {
        brandId: null,
        ...this.productDetail,
      },
      selectedTreatmentType:
        this.productDetail && this.productDetail.treatmentType
          ? this.productDetail.treatmentType._id
          : "",
      isRequired: false,
      organizationId: null,
      searchApplicableArea: "",
      isCustomAreaEdit: false,
      selectedCustomArea: {
        _id: null,
        name: null,
      },
      showAddApplicableAreaField: false,
      createBodyPartError: null,
      updateApplicableAreaError: null,
      dynamicPadding: "py-0",
      deletePopup: false,
      isDeletingApplicableArea: false,
      hasBodyParts: true,
      initialBodyParts: [],
    };
  },
  created() {
    this.organizationId = localStorage.getItem(
      `${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`
    );
    this.getBodyParts();
    this.fetchTreatmentTypes();
    this.fetchAftercareTemplate();
    if (this.productDetail.timingOverride) {
      this.isRequired = this.productDetail.timingOverride;
    }
    if (this.productDetail.applicableAreas) {
      this.initialBodyParts = [...this.productDetail.applicableAreas];
    }
  },
  computed: {
    optionBodyParts() {
      return this.bodyParts.map((b) => {
        return b.name;
      });
    },
    isIvTheraphy() {
      const treatment = this.treatmentTypesOption.filter(
        (item) => item._id == this.selectedTreatmentType
      );
      if (treatment.length > 0) {
        return treatment[0].name == "IV Therapy"; //&& this.$isUsRegion
      }
      return false;
    },
  },
  watch: {
    "formData.timingOverride": function (val) {
      this.isRequired = val;
    },
    popupBodyPartsAdd(newVal) {
      if (newVal) {
        // When sidebar is active, disable scroll on body
        document.body.style.overflow = "hidden";
      } else {
        // When sidebar is inactive, re-enable scroll
        document.body.style.overflow = "";
      }
    },
    "applicableArea.name": {
      handler(val) {
        this.createBodyPartError = null;
      },
    },
    bodyParts: {
      handler(val) {
        this.updateApplicableAreaError = null;
      },
      deep: true,
    },
    formData: {
      handler() {
        this.$nextTick(this.updatePadding);
      },
      deep: true,
    },
    "formData.applicableAreas": {
      handler(val) {
        this.hasBodyParts = val.length > 0;
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions("product", [
      "fetchAllProductApplicableAreasV2",
      "fetchProductApplicableAreas",
      "saveNewCustomApplicableArea",
      "updateCustomApplicableArea",
      "deleteCustomApplicableArea",
    ]),
    ...mapActions("treatmentTypes", ["treatmentTypes"]),
    ...mapActions("aftercare", ["fetchTemplates"]),
    async handleDeleteApplicableArea() {
      try {
        this.isDeletingApplicableArea = true;
        const res = await this.deleteCustomApplicableArea({
          id: this.selectedCustomArea._id,
          payload: {
            organizationId: this.organizationId,
          },
        });

        const selectedApplicableAreaIndex =
          this.formData.applicableAreas.indexOf(this.selectedCustomArea.name);
        if (res.status === 200) {
          if (selectedApplicableAreaIndex > -1) {
            this.formData.applicableAreas.splice(
              selectedApplicableAreaIndex,
              1
            );
          }
          this.handleCancelDelete();
          this.isDeletingApplicableArea = false;
          this.getBodyParts();
        }
      } catch (err) {
        this.isDeletingApplicableArea = false;
        console.error(err);
      }
    },
    handleCancelDelete() {
      this.deletePopup = false;
      this.selectedCustomArea = {
        _id: null,
        name: null,
      };
    },
    handleOpenDeletePopup(item) {
      this.selectedCustomArea = { ...item };
      this.deletePopup = true;
    },
    updatePadding() {
      const container = this.$refs.bodyPartContainer;
      if (container && container.offsetHeight > 45) {
        this.dynamicPadding = "py-1";
      } else {
        this.dynamicPadding = "py-0";
      }
    },
    async handleEditApplicableArea(item) {
      try {
        const isValid = await this.$validator.validateAll("applicableArea");
        if (isValid) {
          const payload = {
            id: item._id,
            payload: {
              name: item.name,
              organizationId: this.organizationId,
            },
          };
          const selectedApplicableAreaIndex =
            this.formData.applicableAreas.indexOf(this.selectedCustomArea.name);
          const res = await this.updateCustomApplicableArea(payload);
          if (res.status == 200) {
            if (selectedApplicableAreaIndex > -1) {
              this.formData.applicableAreas[selectedApplicableAreaIndex] =
                item.name;
            }
            this.isCustomAreaEdit = false;
            this.selectedCustomArea = {
              _id: null,
              name: null,
            };
            this.getBodyParts(this.searchApplicableArea);
          }
        }
      } catch (err) {
        this.updateApplicableAreaError = err.data.message;
        console.error("ERR: ", err);
      }
    },
    handleCancelCreateBodyPart() {
      this.applicableArea.name = "";
      this.createBodyPartError = null;
      this.showAddApplicableAreaField = false;
    },
    handleSaveAreaSidebar() {
      if (this.formData.applicableAreas.length) {
        this.initialBodyParts = [...this.formData.applicableAreas];
      }
      this.searchApplicableArea = "";
      this.popupBodyPartsAdd = false;
      this.showAddApplicableAreaField = false;
      this.getBodyParts();
    },
    handleCancelEditApplicableArea() {
      this.isCustomAreaEdit = false;
      this.selectedCustomArea = {
        _id: null,
        name: null,
      };
      this.getBodyParts(this.searchApplicableArea);
    },
    handleOpenApplicableAreaField(item) {
      this.selectedCustomArea = { ...item };
      this.isCustomAreaEdit = true;
    },
    customFilter: _.debounce(function (data) {
      this.searchApplicableArea = data;
      this.getBodyParts(data);
    }, 500),
    handleCloseAreaSidebar() {
      this.formData.applicableAreas = [...this.initialBodyParts];
      this.popupBodyPartsAdd = false;
      this.showAddApplicableAreaField = false;
      this.isCustomAreaEdit = false;
      this.selectedCustomArea = {
        _id: null,
        name: null,
      };
      this.searchApplicableArea = "";
      this.getBodyParts();
    },
    fetchAftercareTemplate() {
      this.fetchTemplates({ organizationId: this.organizationId }).then(
        (res) => {
          this.aftercareTemplatesOption = res.data.data;
        }
      );
    },
    fetchTreatmentTypes() {
      this.treatmentTypes({ search: "" }).then((res) => {
        this.treatmentTypesOption = res.data.data;
      });
    },
    async getBodyParts(search = "") {
      this.fetchAllProductApplicableAreasV2({
        search,
        organizationId: this.organizationId,
      }).then((res) => {
        this.bodyParts = res.data.data.docs;
      });
    },
    async addBodyParts() {
      let isValid = await this.$validator.validateAll("body-parts-add");
      if (!isValid) {
        this.$vs.notify({
          title: "Error",
          text: "Validation Failed",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
        });
        return false;
      }
      if (this.formData.applicableAreas.includes(this.newBodyParts.name)) {
        this.$vs.notify({
          title: "Error",
          text: "The selected body parts already exists in body parts.",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
        });
        return false;
      }
      this.formData.applicableAreas.push(this.newBodyParts.name);
      this.popupActivo2 = false;
    },
    openRemovePopup(index) {
      this.removeIndex = index;
      this.popUpDelete = true;
    },
    removeBodyParts(index) {
      this.formData.applicableAreas.splice(index, 1);
      this.popUpDelete = false;
    },
    addOnlyBodyPartsPopupShow() {
      this.popupActivo2 = false;
      this.popupBodyPartsAdd = true;
    },
    async addNewBodyParts() {
      let isValid = await this.$validator.validateAll("applicableArea");

      if (isValid) {
        this.saveNewCustomApplicableArea({
          name: this.applicableArea.name,
          organizationId: this.organizationId,
        })
          .then((res) => {
            this.showAddApplicableAreaField = false;
            this.applicableArea.name = "";
            this.getBodyParts();
            this.$vs.notify({
              title: "Success",
              text: "Applicable Area added successfully",
              color: "success",
            });
            this.$vs.loading.close();
          })
          .catch((err) => {
            this.createBodyPartError = err.data.message;
            this.$vs.loading.close();
          });
      }
    },
    async saveData() {
      this.validateBodyParts();
      let isValid = await this.$validator.validateAll("basic");
      if (!isValid || !this.hasBodyParts) {
        this.$vs.notify({
          title: "Error",
          text: "Validation Failed",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
        });
        return false;
      }
      this.$emit(
        "saveDetail",
        this.formData,
        this.selectedTreatmentType,
        this.treatmentTypesOption
      );
    },
    validateBodyParts() {
      if (!this.formData.applicableAreas.length) {
        this.hasBodyParts = false;
        return;
      }
      this.hasBodyParts = true;
    }
  },
  mounted() {
    this.updatePadding();
    window.addEventListener("resize", this.updatePadding);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.updatePadding);
    this.popupBodyPartsAdd = false;
    this.showAddApplicableAreaField = false;
    this.isCustomAreaEdit = false;
  },
};
</script>

<style lang="scss">
.customBodyPartsErrBorder {
  border: 1px solid rgba(var(--vs-danger), 1) !important;
}
.deleteAreaClass {
  z-index: 99999;
  position: absolute;
  right: 0;
  background: white;
  padding: 8px 16px;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  top: 0;
}
.bodyPartClass {
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 4px;
  border-radius: 5px;
  min-height: 38px;
}
.areaClass {
  border: 1px solid rgba(60, 60, 60, 0.26);
  color: #626262;
  background-color: #f0f0f0;
  font-size: 0.9rem;
}
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.5s ease-in-out;
}
.slide-fade-enter,
.slide-fade-leave-to {
  opacity: 0;
  transform: translateY(-20px);
}
.customButtonWidth {
  padding: 9px 5px !important;
}
.customInputClass .vs-input--input.normal {
  padding: 6px 10px !important;
}
.custom-checkbox .vs-checkbox {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
}
.custom-checkbox {
  display: flex;
  align-items: flex-start;
  max-width: 100%;
}
.custom-checkbox span {
  white-space: normal;
  word-break: break-word;
}
.footerClass {
  background-color: white;
  padding: 16px;
  z-index: 10;
  box-shadow: 0 -2px 2px rgba(0, 0, 0, 0.1);
}
.scrollableContent {
  overflow-y: auto;
  padding-top: 14px;
  padding-bottom: 14px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-left: 8px;
  height: 100%;
  position: relative;
}
.headerClass {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 10;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
}
.applicableAreaClass .vs-sidebar-primary {
  display: flex;
  flex-direction: column;
  max-width: none;
}
.applicableAreaClass .vs-sidebar--items {
  display: flex;
  flex-direction: column;
  padding: 0;
}
@media (min-width: 768px) {
  .applicableAreaClass .vs-sidebar-primary {
    max-width: 450px;
  }
}
</style>